.restaurantsGridWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.refresh-button {
    align-self: flex-end;
    margin-top: 10px; /* Add some space between the grid and the button */
}
