.portal-login-wrapper {
    width: 50%;
    margin: 10% auto auto auto;
    padding: 20px;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 10px;

    .btn-lg {
        padding: 5px 15px 5px 15px;
        font-size: 17px;
    }

    .form-control {
        margin-bottom: 30px;
    }
}

