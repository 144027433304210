.restaurant-manager-wrapper {
    width: 90%;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;

    .input {
        display: inline-block;
        width: 80%;
    }
    
    .button {
        display: inline-block;
        margin-top: 32px;
        margin-right: 16px;
    }
}

